<template>
    <div class="sizeGuide">
        <div class="warp">
            <h5>Size Guide</h5>
            <div class="size">
                <div class="title">
                    <span @click="sizeClick(0)" :class="ative == 0 ? 'ative' : ''">CM</span>
                    <span @click="sizeClick(1)" :class="ative == 1 ? 'ative' : ''">IN</span>
                </div>
                <div class="table">
                    <div class="head">
                        <div class="tr">
                            <div class="td">
                                <span>Size</span>
                            </div>
                            <div class="td">
                                <span>Length</span>
                            </div>
                            <div class="td">
                                <span>Shoulder</span>
                            </div>
                            <div class="td">
                                <span>Bust</span>
                            </div>
                            <div class="td">
                                <span>Waist</span>
                            </div>
                            <div class="td">
                                <span>Sleeve</span>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                        <div class="tr" v-for="(item, index) in list" :key="index">
                            <div class="td">
                                <span>{{item.Size}}</span>
                            </div>
                            <div class="td">
                                <span>{{item.Length}}</span>
                            </div>
                            <div class="td">
                                <span>{{item.Shoulder}}</span>
                            </div>
                            <div class="td">
                                <span>{{item.Bust}}</span>
                            </div>
                            <div class="td">
                                <span>{{item.Waist}}</span>
                            </div>
                            <div class="td">
                                <span>{{item.Sleeve}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <p>*The above data is obtained by manually measuring the product, May deviate by 1~3 cm.</p>
            </div>
            <div class="product">
                <h5>How to measurements Product’s Size?</h5>
                <div class="main">
                    <div class="textMain">
                        <div class="text">
                            <span>1.Shoulder width</span>
                            <p>Measure from where the shoulder seam meets the sleeve on one side to another side.</p>
                        </div>
                        <div class="text">
                            <span>2.Bust</span>
                            <p>Measure from the stitches below the armpits on one side to another.</p>
                        </div>
                        <div class="text">
                            <span>3.Sleeves</span>
                            <p>Measure from where the shoulder seam meets armhole to the cuff. </p>
                        </div>
                        <div class="text">
                            <span>4.Top Length</span>
                            <p>Measure from where the shoulder seam meets the collar to the hem.</p>
                        </div>
                        <div class="text">
                            <span>5.Bottom Waist</span>
                            <p>Measure straight across the top of the waistband from edge to edge.</p>
                        </div>
                        <div class="text">
                            <span>6.Hips</span>
                            <p>Measure straight across the widest hip line from edge to edge.</p>
                        </div>
                        <div class="text">
                            <span>7.Thigh</span>
                            <p>Measure across straight from the crotch seam to the outside of the leg. </p>
                        </div>
                        <div class="text">
                            <span>8.Bottom Length</span>
                            <p>Measure from the waistband to the leg opening or hem.</p>
                        </div>
                    </div>
                    <div class="textImg">
                        <div class="img">
                            <el-image :src="require('@/assets/images/index/component/SizeGuide/clothing.png')" fit="cover"></el-image>
                        </div>
                        <div class="img">
                            <el-image :src="require('@/assets/images/index/component/SizeGuide/pants.png')" fit="cover"></el-image>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="guide">
                <h5>Where to find the size guide?</h5>
                <div class="text-img">
                    <p>Click on the 'size & fit' button on each product page to find out the measurements which you can view in centimeters and inches, and how to measure to make sure it fits.</p>
                    <div class="img">
                        <el-image :src="require('@/assets/images/index/component/SizeGuide/img.png')" fit="cover"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sizeGuide",
    data: () => {
        return {
            ative: 0,
            sizeList: {
                cm: [
                    {
                        Size: "S",
                        Length: "73",
                        Shoulder: "39",
                        Bust: "94",
                        Waist: "84",
                        Sleeve: "20"
                    },{
                        Size: "M",
                        Length: "74",
                        Shoulder: "40",
                        Bust: "98",
                        Waist: "88",
                        Sleeve: "20.5"
                    },{
                        Size: "L",
                        Length: "75.5",
                        Shoulder: "41.5",
                        Bust: "104",
                        Waist: "94",
                        Sleeve: "21.2"
                    },{
                        Size: "XL",
                        Length: "77",
                        Shoulder: "43",
                        Bust: "110",
                        Waist: "100",
                        Sleeve: "22"
                    },{
                        Size: "XXL",
                        Length: "78.5",
                        Shoulder: "44.5",
                        Bust: "116",
                        Waist: "106",
                        Sleeve: "22.7"
                    }
                ],
                in: [
                    {
                        Size: "S",
                        Length: "28.74",
                        Shoulder: "15.35",
                        Bust: "37",
                        Waist: "33.07",
                        Sleeve: "7.87"
                    },{
                        Size: "M",
                        Length: "29.13",
                        Shoulder: "15.75",
                        Bust: "38.58",
                        Waist: "34.65",
                        Sleeve: "8.07"
                    },{
                        Size: "L",
                        Length: "29.72",
                        Shoulder: "16.33",
                        Bust: "40.94",
                        Waist: "37",
                        Sleeve: "8.35"
                    },{
                        Size: "XL",
                        Length: "30.31",
                        Shoulder: "16.93",
                        Bust: "16.93",
                        Waist: "39.37",
                        Sleeve: "8.66"
                    },{
                        Size: "XXL",
                        Length: "30.91",
                        Shoulder: "17.52",
                        Bust: "45.67",
                        Waist: "41.73",
                        Sleeve: "8.94"
                    }
                ]
            },
            list: []
        }
    },
    created() {
        this.list = this.sizeList.cm;
    },
    mounted() {
        
    },
    methods: {
        sizeClick(index) {
            if(index == 0) {
                this.ative = 0;
                this.list = this.sizeList.cm;
            }else if(index == 1){
                this.ative = 1;
                this.list = this.sizeList.in;
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.sizeGuide{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 110px;

    .warp{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        >h5{
            font-size: 30px;
            font-family: Montserrat;
            font-weight: bold;
            color: #330000;
            text-align: center;
            line-height: 1;
            padding-bottom: 90px;
        }

        .size{
            display: flex;
            flex-direction: column;
            padding-bottom: 60px;
            border-bottom: 1px solid #ECEEEE;
            
            .title{
                display: flex;
                padding-bottom: 20px;

                .ative{
                    color: #fff;
                    background: #330000;
                }

                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80px;
                    height: 44px;
                    font-size: 20px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #330000;
                    line-height: 1;
                    border: 1px solid #330000;
                    cursor: pointer;
                }
            }

            .table{
                width: 70%;
                display: flex;
                flex-direction: column;
                border: 1px solid #E1E3E4;

                .head{
                    background: #F1F3F4;

                    .tr{
                        .td{
                            span{
                                font-size: 14px;
                                font-family: Montserrat;
                                font-weight: bold;
                                color: #330000;
                                line-height: 1;
                            }
                        }

                        .td:last-child{
                            border-right: none;
                        }
                    }
                }

                .tr{
                    height: 44px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #E1E3E4;

                    .td{
                        width: 138px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #E1E3E4;
                    }
                }

                .body{
                    display: flex;
                    flex-direction: column;

                    .tr:last-child{
                        border-bottom: none;
                    }

                    .tr{
                        .td:first-child{
                            span{
                                font-size: 14px;
                                font-family: Montserrat;
                                font-weight: bold;
                                color: #330000;
                                line-height: 1;
                            }
                        }

                        .td{
                            font-size: 14px;
                            font-family: Montserrat;
                            font-weight: 400;
                            color: #330000;
                            line-height: 1;
                        }

                        .td:last-child{
                            border-right: none;
                        }
                    }
                }
            }

            >p{
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                color: #A6AAAD;
                line-height: 1;
                padding-top: 20px;
            }
        }

        .product{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 60px 0;
            border-bottom: 1px solid #ECEEEE;

            >h5{
                font-size: 20px;
                font-family: Montserrat;
                font-weight: bold;
                color: #330000;
                line-height: 1;
                padding-bottom: 40px;
            }

            .main{
                width: 100%;
                display: flex;
                justify-content: space-between;

                .textMain{
                    display: flex;
                    flex-direction: column;

                    .text{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 30px;

                        span{
                            font-size: 16px;
                            font-family: Montserrat;
                            font-weight: bold;
                            color: #330000;
                            line-height: 1;
                            padding-bottom: 14px;
                        }

                        p{
                            font-size: 14px;
                            font-family: Montserrat;
                            font-weight: 400;
                            color: #68696A;
                            line-height: 24px;
                        }
                    }
                }

                .textImg{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
        
        .guide{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 60px;

            >h5{
                font-size: 22px;
                font-family: Montserrat;
                font-weight: bold;
                color: #330000;
                line-height: 1;
                padding-bottom: 40px;
            }

            .text-img{
                width: 100%;
                display: flex;
                justify-content: space-between;

                p{
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #330000;
                    line-height: 24px;
                    width: 574px;
                }

                .img{
                    width: 586px;
                }
            }
        }
    }
}
</style>